/* global BigInt */
import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Avatar,
  Box,
  InputAdornment,
  CircularProgress,
  Grid,
  Snackbar,
  Alert,
  ToggleButtonGroup,
  ToggleButton,
  useMediaQuery,
  useTheme,
  Skeleton,
  Divider,
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoIcon from '@mui/icons-material/Info';
import WalletIcon from '@mui/icons-material/AccountBalanceWallet';
import TicketIcon from '@mui/icons-material/ConfirmationNumber';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  LabelList,
  Cell,
} from 'recharts';
import { motion } from 'framer-motion'; // For animations using framer-motion
import { WalletContext } from '../WalletProvider'; // Adjust the import path as needed

// Styled Components

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center', // Center content horizontally
  minHeight: '100vh',
  padding: theme.spacing(5, 2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3, 1),
  },
}));

const MainGrid = styled(Grid)(({ theme }) => ({
  maxWidth: 1200,
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  backgroundColor: '#ffffff',
  border: '1px solid #e0e0e0', // Subtle border
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Soft shadow
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(3),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const HeaderTypography = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 700,
  marginBottom: theme.spacing(3),
  color: theme.palette.text.primary,
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  borderRadius: theme.spacing(0.5),
  fontSize: '1rem',
  textTransform: 'none',
  color: '#fff',
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const SmallButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1, 2.5),
  borderRadius: theme.spacing(0.5),
  fontSize: '0.95rem',
  textTransform: 'none',
  color: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

// Styled components for the ticket
const StyledTicketCard = styled(Box)(({ theme }) => ({
  width: 250,
  height: 400,
  backgroundColor: '#fff',
  borderRadius: theme.spacing(2),
  boxShadow: '0 8px 12px rgba(0,0,0,0.1)',
  margin: '0 auto',
  position: 'relative',
  overflow: 'hidden',
  backgroundImage: 'linear-gradient(135deg, #f5f7fa 25%, #c3cfe2 100%)',
  border: '1px solid #d3d3d3',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const TicketHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(2),
  color: '#fff',
  textAlign: 'center',
}));

const TicketFooter = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(1),
  color: '#fff',
  textAlign: 'center',
}));

const TicketContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  flexGrow: 1,
}));

const GradientBox = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(135deg, #6D5FFD 0%, #46A0FF 100%)', // Modern gradient
  borderRadius: theme.spacing(2),
  padding: theme.spacing(3),
  color: '#fff', // White text for better contrast
  textAlign: 'center',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Soft shadow
  position: 'relative',
  overflow: 'hidden',
}));

const AnimatedBox = styled(motion.div)``; // For animations using framer-motion

// Prize Distribution Component
const PrizeDistribution = ({ prizes, loading }) => {
  const [selectedAsset, setSelectedAsset] = useState('ERMO');
  const [activeBarIndex, setActiveBarIndex] = useState(null);

  const theme = useTheme();

  const prizeData = prizes[selectedAsset];

  const chartData = [
    { name: 'First Prize', value: prizeData.firstPrize },
    { name: 'Second Prize', value: prizeData.secondPrize },
    { name: 'Third Prize', value: prizeData.thirdPrize },
    { name: 'Burn', value: prizeData.burn },
    { name: 'Jackpot', value: prizeData.jackpot },
  ];

  const COLORS = ['#6D5FFD', '#46A0FF', '#FF6B6B', '#FFD93D', '#1DD1A1']; // Modern colors

  const handleBarMouseOver = (data, index) => {
    setActiveBarIndex(index);
  };

  const handleBarMouseOut = () => {
    setActiveBarIndex(null);
  };

  // Custom label to ensure large numbers are displayed fully
  const renderCustomLabel = (props) => {
    const { x, y, width, height, value } = props;
    const dx = 5; // Adjust as needed
    return (
      <text
        x={x + width + dx}
        y={y + height / 2}
        fill="#333"
        textAnchor="start"
        dominantBaseline="middle"
        style={{ fontSize: 12, fontWeight: 'bold' }}
      >
        {value.toLocaleString()}
      </text>
    );
  };

  return (
    <StyledCard>
      <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: 2 }}>
        Prize Distribution - {selectedAsset}
      </Typography>
      <Box display="flex" justifyContent="center" sx={{ marginBottom: 2 }}>
        <ToggleButtonGroup
          value={selectedAsset}
          exclusive
          onChange={(e, newAsset) => {
            if (newAsset !== null) {
              setSelectedAsset(newAsset);
            }
          }}
          aria-label="Asset Selection"
        >
          <ToggleButton value="ERMO" aria-label="ERMO">
            ERMO
          </ToggleButton>
          <ToggleButton value="ADA" aria-label="ADA">
            ADA
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height={300}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ width: '100%', height: 300 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={chartData}
              layout="vertical"
              margin={{ top: 20, bottom: 20, left: 0, right: 80 }} // Increased right margin
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" stroke="#333" hide />
              <YAxis
                type="category"
                dataKey="name"
                stroke="#333"
                width={120} // Increased width for category labels
                tick={{ fontSize: 12 }}
              />
              <RechartsTooltip
                formatter={(value) => value.toLocaleString()}
                contentStyle={{ border: '1px solid #ccc', color: '#000' }}
                itemStyle={{ color: '#000' }}
                labelStyle={{ color: '#000' }}
              />
              <Bar
                dataKey="value"
                isAnimationActive={true}
                animationDuration={800}
                animationEasing="ease-out"
                onMouseOver={handleBarMouseOver}
                onMouseOut={handleBarMouseOut}
                radius={[10, 10, 10, 10]}
                barSize={20}
              >
                {chartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    fillOpacity={index === activeBarIndex ? 0.7 : 1}
                  />
                ))}
                <LabelList dataKey="value" content={renderCustomLabel} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>
      )}
      {/* Total Prize Pool at the bottom */}
      <Box
        sx={{
          textAlign: 'center',
          marginTop: 2,
          overflowWrap: 'break-word',
          wordBreak: 'break-word',
        }}
      >
        {loading ? (
          <Skeleton variant="text" width={200} height={30} sx={{ margin: '0 auto' }} />
        ) : (
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Total Prize Pool: {prizeData.totalPrizePool.toLocaleString()} {selectedAsset}
          </Typography>
        )}
      </Box>
    </StyledCard>
  );
};

// Timer Component
const Timer = ({ endTime, onCountdownEnd }) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    // Fetch current time from World Time API on initial load
    const fetchTime = async () => {
      const fetchedTime = await fetchCurrentTime();
      setCurrentTime(fetchedTime);
      const calculatedTimeLeft = calculateTimeLeft(fetchedTime, endTime);
      setTimeLeft(calculatedTimeLeft);
    };

    fetchTime();

    const timerInterval = setInterval(() => {
      setCurrentTime((prevTime) => {
        const newTime = new Date(prevTime.getTime() + 1000); // Increment by 1 second
        const calculatedTimeLeft = calculateTimeLeft(newTime, endTime);

        if (!calculatedTimeLeft) {
          clearInterval(timerInterval); // Stop interval if countdown is over
          if (onCountdownEnd) {
            onCountdownEnd(); // Notify parent component
          }
        }

        setTimeLeft(calculatedTimeLeft);
        return newTime;
      });
    }, 1000);

    return () => clearInterval(timerInterval); // Cleanup interval on unmount
  }, [endTime, onCountdownEnd]);

  if (!timeLeft) {
    return (
      <StyledCard>
        <Typography variant="h5" sx={{ fontSize: '1.5rem', textAlign: 'center' }}>
          Lottery Ended
        </Typography>
      </StyledCard>
    );
  }

  return (
    <GradientBox>
      <Typography variant="h5" sx={{ fontSize: '1.5rem', marginBottom: 2 }}>
        Lottery Ends In:
      </Typography>
      <Box display="flex" justifyContent="center" alignItems="center" gap={2} flexWrap="wrap">
        {['Days', 'Hours', 'Minutes', 'Seconds'].map((unit, index) => {
          const value = [timeLeft.days, timeLeft.hours, timeLeft.minutes, timeLeft.seconds][index];
          return (
            <Box key={unit} textAlign="center" sx={{ minWidth: 60 }}>
              <AnimatedBox
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                  {String(value).padStart(2, '0')}
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  {unit}
                </Typography>
              </AnimatedBox>
            </Box>
          );
        })}
      </Box>
    </GradientBox>
  );
};

// Helper functions for Timer
const fetchCurrentTime = async () => {
  try {
    const response = await axios.get('https://worldtimeapi.org/api/ip');
    const data = response.data;
    return new Date(data.utc_datetime); // Return the UTC time
  } catch (error) {
    console.error('Error fetching current time:', error);
    return new Date(); // Fallback to local time if API fails
  }
};

const calculateTimeLeft = (currentTime, endTime) => {
  const endDate = new Date(endTime).getTime(); // Convert end time to UTC milliseconds
  const currentTimestamp = currentTime.getTime(); // Current UTC time in milliseconds

  const difference = endDate - currentTimestamp; // Time difference in milliseconds

  if (difference > 0) {
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((difference % (1000 * 60)) / 1000),
    };
  } else {
    return null; // Countdown is complete
  }
};

const Lottery = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    lucid,
    walletAddress,
    isConnected,
    isInitializing,
    connectWallet,
    disconnectWallet,
  } = useContext(WalletContext); // Consume the wallet context

  const [customAmount, setCustomAmount] = useState('');
  const [selectedAsset, setSelectedAsset] = useState('ERMO');
  const [openPopup, setOpenPopup] = useState(false);
  const [popupDetails, setPopupDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [walletBalances, setWalletBalances] = useState({
    ERMO: 0,
    ADA: 0,
    jackpot: 0,
  });
  const [tickets, setTickets] = useState(0);
  const [endTime, setEndTime] = useState(null);
  const [isCountdownOver, setIsCountdownOver] = useState(false);
  const [ticketsSold, setTicketsSold] = useState({
    ERMO: 0,
    ADA: 0,
  });
  const [myTickets, setMyTickets] = useState({
    ERMO: null,
    ADA: null,
    ticketTransactions: [],
  }); // State to store user's tickets
  const [loadingMyTickets, setLoadingMyTickets] = useState(false); // Loading state for My Tickets
  const [openTicketDialog, setOpenTicketDialog] = useState(false); // State for ticket popup

  // New state to store total amounts from ticket transactions
  const [totalAmounts, setTotalAmounts] = useState({
    ERMO: 0,
    ADA: 0,
  });

  // Loading state for data fetching
  const [loadingData, setLoadingData] = useState(true);

  // Snackbar State
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success', // 'error', 'warning', 'info', 'success'
  });

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleAssetChange = (event) => {
    setSelectedAsset(event.target.value);
    setCustomAmount('');
    setTickets(0);
  };

  // Define the ERMO Asset Identifier without the dot separator
  const ERMO_ASSET_IDENTIFIER =
    'e1a212122b07460a739af69751bd01cd03174791f778b2365cc0b8f645524d4f'; // Concatenated PolicyID and AssetNameHex

  // Asset Ticket Mapping
  const assetTicketMapping = {
    ERMO: {
      identifier: ERMO_ASSET_IDENTIFIER,
      multiple: 100, // 100 ERMO = 1 ticket
    },
    ADA: {
      identifier: 'lovelace',
      multiple: 10, // 10 ADA = 1 ticket
    },
  };

  const handleCustomAmountChange = (event) => {
    const value = event.target.value;
    setCustomAmount(value);

    // Calculate tickets based on input amount and selected asset
    const amount = Number(value);
    const requiredMultiple = assetTicketMapping[selectedAsset].multiple;

    if (!isNaN(amount) && amount >= requiredMultiple) {
      setTickets(Math.floor(amount / requiredMultiple));
    } else {
      setTickets(0);
    }
  };

  const handleCopyPayout = () => {
    if (popupDetails.txHash) {
      navigator.clipboard.writeText(popupDetails.txHash).then(
        () => {
          setSnackbar({
            open: true,
            message: 'Transaction hash copied to clipboard!',
            severity: 'success',
          });
        },
        (err) => {
          console.error('Could not copy text: ', err);
          setSnackbar({
            open: true,
            message: 'Failed to copy hash.',
            severity: 'error',
          });
        }
      );
    }
  };

  const handleCreateTransaction = async () => {
    if (!isConnected) {
      setSnackbar({
        open: true,
        message: 'Please connect your wallet before creating a transaction.',
        severity: 'warning',
      });
      return;
    }

    if (!lucid) {
      setSnackbar({
        open: true,
        message: 'Lucid is not initialized. Please try again later.',
        severity: 'error',
      });
      return;
    }

    let amount = Number(customAmount);
    if (!selectedAsset) {
      setSnackbar({
        open: true,
        message: 'Please select an asset.',
        severity: 'warning',
      });
      return;
    }

    const requiredMultiple = assetTicketMapping[selectedAsset].multiple;

    if (!amount || isNaN(amount)) {
      setSnackbar({
        open: true,
        message: 'Please enter a valid amount.',
        severity: 'warning',
      });
      return;
    }
    if (amount < requiredMultiple) {
      setSnackbar({
        open: true,
        message: `Amount must be at least ${requiredMultiple} ${selectedAsset}.`,
        severity: 'warning',
      });
      return;
    }
    if (amount % requiredMultiple !== 0) {
      const roundedAmount = Math.round(amount / requiredMultiple) * requiredMultiple;
      setSnackbar({
        open: true,
        message: `Amount must be a multiple of ${requiredMultiple} ${selectedAsset}. Rounding to nearest ${requiredMultiple}: ${roundedAmount} ${selectedAsset}.`,
        severity: 'info',
      });
      setCustomAmount(roundedAmount);
      setTickets(Math.floor(roundedAmount / requiredMultiple));
      return;
    }

    const numTickets = amount / assetTicketMapping[selectedAsset].multiple; // Calculate number of tickets

    try {
      setLoading(true);
      // Fetch recipientAddress from Lottery.json
      const response = await axios.get(`${process.env.PUBLIC_URL}/Lottery.json`);
      const lotteryData = response.data;
      const recipientAddress = lotteryData.recipientAddress;

      if (!recipientAddress) {
        throw new Error('Recipient address is missing in Lottery.json.');
      }

      let txBuilder = lucid.newTx();

      if (selectedAsset === 'ERMO') {
        txBuilder = txBuilder.payToAddress(recipientAddress, {
          [assetTicketMapping['ERMO'].identifier]: BigInt(amount),
        });
      } else if (selectedAsset === 'ADA') {
        txBuilder = txBuilder.payToAddress(recipientAddress, {
          lovelace: BigInt(amount * 1000000), // Convert ADA to Lovelace
        });
      }

      // Attach metadata directly based on asset type
      txBuilder = txBuilder.attachMetadata(1, {
        lotteryId: 'lottery123',
        tickets: numTickets,
        assetType: selectedAsset,
      });

      const tx = await txBuilder.complete();
      const signedTx = await tx.sign().complete();
      const txHash = await signedTx.submit();
      console.log('Transaction submitted:', txHash);

      // Wait for the transaction to be confirmed
      await lucid.awaitTx(txHash);

      setPopupDetails({
        txHash,
        amount: amount,
        tickets: numTickets,
        asset: selectedAsset,
      });
      setOpenPopup(true);
      setSnackbar({
        open: true,
        message: 'Transaction successful!',
        severity: 'success',
      });
      setLoading(false);

      // Refresh data after transaction
      fetchLotteryData(); // Fetch updated total tickets sold
      handleMyTickets(); // Fetch updated tickets for the user
    } catch (error) {
      console.error('Error creating transaction:', error.message);
      setSnackbar({
        open: true,
        message: `Error creating the transaction: ${error.message}`,
        severity: 'error',
      });
      setLoading(false);
    }
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const fetchLotteryData = async () => {
    try {
      setLoadingData(true);
      const response = await axios.get(`${process.env.PUBLIC_URL}/Lottery.json`);
      const lotteryData = response.data;

      // Get recipientAddress
      const recipientAddress = lotteryData.recipientAddress;

      if (!recipientAddress) {
        throw new Error('Recipient address is missing in Lottery.json.');
      }

      if (!lucid) {
        setSnackbar({
          open: true,
          message: 'Lucid is not initialized. Please try again later.',
          severity: 'error',
        });
        setLoadingData(false);
        return;
      }

      // Fetch total tickets sold and total amounts from ticket transactions
      const totalTicketsData = await fetchTotalTicketsSold(recipientAddress);
      setTicketsSold({
        ERMO: totalTicketsData.ERMO.tickets,
        ADA: totalTicketsData.ADA.tickets,
      });

      console.log(
        `Tickets Sold - ERMO: ${totalTicketsData.ERMO.tickets}, ADA: ${totalTicketsData.ADA.tickets}`
      );

      // Use total amounts from ticket transactions to calculate prizes
      setTotalAmounts({
        ERMO: totalTicketsData.ERMO.totalAmount,
        ADA: totalTicketsData.ADA.totalAmount,
      });

      if (lotteryData.endTime) {
        setEndTime(lotteryData.endTime); // Using endTime from Lottery.json
      } else {
        console.error('endTime is missing in Lottery.json');
      }
      setLoadingData(false);
    } catch (error) {
      console.error('Error fetching Lottery.json:', error);
      setSnackbar({
        open: true,
        message: 'Failed to fetch lottery data.',
        severity: 'error',
      });
      setLoadingData(false);
    }
  };

  const fetchTotalTicketsSold = async (recipientAddress) => {
    try {
      // Replace with your Blockfrost API key (ensure it's securely stored)
      const blockfrostApiKey = process.env.REACT_APP_BLOCKFROST_API_KEY;

      if (!blockfrostApiKey) {
        throw new Error(
          'Blockfrost API key is missing. Please set it in your environment variables.'
        );
      }

      // Base URL for Blockfrost API
      const blockfrostBaseUrl = 'https://cardano-mainnet.blockfrost.io/api/v0';

      // Function to make authenticated requests to Blockfrost
      const blockfrostRequest = async (url, params = {}) => {
        return axios.get(url, {
          baseURL: blockfrostBaseUrl,
          headers: {
            project_id: blockfrostApiKey,
          },
          params,
        });
      };

      // Fetch transactions to the lottery address
      let page = 1;
      let txHashes = [];
      let fetchMore = true;

      // Handle pagination to fetch all transactions
      while (fetchMore) {
        const txPageResponse = await blockfrostRequest(
          `/addresses/${recipientAddress}/transactions`,
          {
            order: 'asc',
            page,
            count: 100,
          }
        );

        const txPage = txPageResponse.data;

        if (txPage.length > 0) {
          txHashes = txHashes.concat(txPage.map((tx) => tx.tx_hash));
          page++;
        } else {
          fetchMore = false;
        }
      }

      let ermoTickets = 0;
      let adaTickets = 0;
      let ermoTotalAmount = 0;
      let adaTotalAmount = 0;

      console.log(`Total transactions fetched for tickets: ${txHashes.length}`);

      for (const txHash of txHashes) {
        // Fetch transaction UTXOs to determine assets
        const utxosResponse = await blockfrostRequest(`/txs/${txHash}/utxos`);
        const utxos = utxosResponse.data;

        // Filter UTXOs sent to recipientAddress
        const sentUtxos = utxos.outputs.filter((utxo) => utxo.address === recipientAddress);

        if (sentUtxos.length === 0) {
          console.log(`No UTXOs sent to recipient in transaction: ${txHash}`);
          continue; // No relevant UTXOs in this transaction
        }

        let hasERMO = false;
        let ermoAmount = 0;
        let adaAmount = 0;

        sentUtxos.forEach((utxo) => {
          const amountArray = utxo.amount;
          if (amountArray) {
            amountArray.forEach((amountObj) => {
              const assetKey = amountObj.unit;
              const quantity = amountObj.quantity;
              console.log(`Processing assetKey: ${assetKey}, amount: ${quantity}`);

              if (assetKey === assetTicketMapping['ERMO'].identifier) {
                hasERMO = true;
                ermoAmount += Number(quantity);
              }
              if (assetKey === 'lovelace') {
                adaAmount += Number(quantity);
              }
            });
          }
        });

        if (hasERMO) {
          // Count as ERMO tickets
          const ticketsFromTx = Math.floor(ermoAmount / assetTicketMapping['ERMO'].multiple);
          ermoTickets += ticketsFromTx;
          ermoTotalAmount += ermoAmount;
          console.log(`ERMO Ticket Added: ${ticketsFromTx} from TX: ${txHash}`);
        } else {
          // Count as ADA tickets
          const adaAmountInADA = adaAmount / 1000000; // Convert Lovelace to ADA
          const ticketsFromTx = Math.floor(adaAmountInADA / assetTicketMapping['ADA'].multiple);
          adaTickets += ticketsFromTx;
          adaTotalAmount += adaAmountInADA;
          console.log(`ADA Ticket Added: ${ticketsFromTx} from TX: ${txHash}`);
        }
      }

      console.log(`Total Tickets Sold - ERMO: ${ermoTickets}, ADA: ${adaTickets}`);
      console.log(`Total Amounts - ERMO: ${ermoTotalAmount}, ADA: ${adaTotalAmount}`);

      return {
        ERMO: { tickets: ermoTickets, totalAmount: ermoTotalAmount },
        ADA: { tickets: adaTickets, totalAmount: adaTotalAmount },
      };
    } catch (error) {
      console.error('Error fetching total tickets sold:', error);
      throw error;
    }
  };

  useEffect(() => {
    if (lucid) {
      fetchLotteryData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lucid]);

  const handleMyTickets = async () => {
    if (!isConnected || !walletAddress) {
      setSnackbar({
        open: true,
        message: 'Please connect your wallet to view your tickets.',
        severity: 'warning',
      });
      return;
    }

    try {
      setLoadingMyTickets(true);

      const response = await axios.get(`${process.env.PUBLIC_URL}/Lottery.json`);
      const lotteryData = response.data;

      // Get recipientAddress
      const recipientAddress = lotteryData.recipientAddress;

      if (!recipientAddress) {
        throw new Error('Recipient address is missing in Lottery.json.');
      }

      // Replace with your Blockfrost API key (ensure it's securely stored)
      const blockfrostApiKey = process.env.REACT_APP_BLOCKFROST_API_KEY;

      if (!blockfrostApiKey) {
        throw new Error(
          'Blockfrost API key is missing. Please set it in your environment variables.'
        );
      }

      // Base URL for Blockfrost API
      const blockfrostBaseUrl = 'https://cardano-mainnet.blockfrost.io/api/v0';

      // Function to make authenticated requests to Blockfrost
      const blockfrostRequest = async (url, params = {}) => {
        return axios.get(url, {
          baseURL: blockfrostBaseUrl,
          headers: {
            project_id: blockfrostApiKey,
          },
          params,
        });
      };

      // Fetch transactions to the lottery address
      let page = 1;
      let txHashes = [];
      let fetchMore = true;

      // Handle pagination to fetch all transactions
      while (fetchMore) {
        const txPageResponse = await blockfrostRequest(
          `/addresses/${recipientAddress}/transactions`,
          {
            order: 'asc',
            page,
            count: 100,
          }
        );

        const txPage = txPageResponse.data;

        if (txPage.length > 0) {
          txHashes = txHashes.concat(txPage.map((tx) => tx.tx_hash));
          page++;
        } else {
          fetchMore = false;
        }
      }

      let ermoTickets = 0;
      let adaTickets = 0;
      const ticketTransactions = []; // Store ticket info for carousel

      console.log(`Total transactions fetched for user's tickets: ${txHashes.length}`);

      for (const txHash of txHashes) {
        // Fetch transaction UTXOs to determine assets
        const utxosResponse = await blockfrostRequest(`/txs/${txHash}/utxos`);
        const utxos = utxosResponse.data;

        // Check if the transaction is from the connected wallet
        const isFromWallet = utxos.inputs.some(
          (input) => input.address.toLowerCase() === walletAddress.toLowerCase()
        );

        if (isFromWallet) {
          // Filter UTXOs sent to recipientAddress
          const sentUtxos = utxos.outputs.filter(
            (utxo) => utxo.address.toLowerCase() === recipientAddress.toLowerCase()
          );

          if (sentUtxos.length === 0) {
            continue; // No relevant UTXOs in this transaction
          }

          let hasERMO = false;
          let ermoAmount = 0;
          let adaAmount = 0;

          sentUtxos.forEach((utxo) => {
            const amountArray = utxo.amount;
            if (amountArray) {
              amountArray.forEach((amountObj) => {
                const assetKey = amountObj.unit;
                const quantity = amountObj.quantity;
                console.log(`Processing assetKey: ${assetKey}, amount: ${quantity}`);

                if (assetKey === assetTicketMapping['ERMO'].identifier) {
                  hasERMO = true;
                  ermoAmount += Number(quantity);
                }
                if (assetKey === 'lovelace') {
                  adaAmount += Number(quantity);
                }
              });
            }
          });

          if (hasERMO) {
            // Count as ERMO tickets
            const ticketsFromTx = Math.floor(ermoAmount / assetTicketMapping['ERMO'].multiple);
            ermoTickets += ticketsFromTx;
            console.log(`User ERMO Ticket Added: ${ticketsFromTx} from TX: ${txHash}`);
            for (let i = 0; i < ticketsFromTx; i++) {
              ticketTransactions.push('ERMO');
            }
          } else {
            // Count as ADA tickets
            const adaAmountInADA = adaAmount / 1000000; // Convert Lovelace to ADA
            const ticketsFromTx = Math.floor(adaAmountInADA / assetTicketMapping['ADA'].multiple);
            adaTickets += ticketsFromTx;
            console.log(`User ADA Ticket Added: ${ticketsFromTx} from TX: ${txHash}`);
            for (let i = 0; i < ticketsFromTx; i++) {
              ticketTransactions.push('ADA');
            }
          }
        }
      }

      console.log(`Total User Tickets - ERMO: ${ermoTickets}, ADA: ${adaTickets}`);

      setMyTickets({
        ERMO: ermoTickets,
        ADA: adaTickets,
        ticketTransactions,
      });
      setLoadingMyTickets(false);
      setOpenTicketDialog(true); // Open the ticket popup
    } catch (error) {
      console.error('Error fetching your tickets:', error);
      setSnackbar({
        open: true,
        message: 'Failed to fetch your tickets.',
        severity: 'error',
      });
      setLoadingMyTickets(false);
    }
  };

  // Generate a mock serial number for the ticket
  const generateSerialNumber = () => {
    // Simple random serial number generator
    return 'SN-' + Math.floor(100000 + Math.random() * 900000);
  };

  // Calculate prize distributions using totalAmounts from ticket transactions
  const calculatePrizes = (asset) => {
    const balance = totalAmounts[asset] || 0;
    const totalPrizesPool = balance * 0.9; // 90% of balance

    const firstPrize = totalPrizesPool * 0.5; // 50% of 90%
    const secondPrize = totalPrizesPool * 0.3; // 30% of 90%
    const thirdPrize = totalPrizesPool * 0.2; // 20% of 90%

    const burn = balance * 0.05; // 5% of balance for both ERMO and ADA
    const jackpot = balance * 0.05 + (walletBalances.jackpot || 0); // 5% of balance + jackpot from Lottery.json

    return {
      firstPrize: firstPrize || 0,
      secondPrize: secondPrize || 0,
      thirdPrize: thirdPrize || 0,
      burn: burn || 0, // Include burn for both ERMO and ADA
      jackpot: jackpot || 0,
      totalPrizePool: totalPrizesPool || 0,
    };
  };

  const prizes = {
    ERMO: calculatePrizes('ERMO'),
    ADA: calculatePrizes('ADA'),
  };

  const handleCloseTicketDialog = () => {
    setOpenTicketDialog(false);
    setMyTickets({ ERMO: null, ADA: null, ticketTransactions: [] }); // Reset tickets
  };

  // Data for Tickets Sold Chart
  const ticketsSoldData = [
    {
      name: 'ERMO',
      tickets: ticketsSold.ERMO,
    },
    {
      name: 'ADA',
      tickets: ticketsSold.ADA,
    },
  ];

  const [activeBarIndex, setActiveBarIndex] = useState(null);

  const handleBarMouseOver = (data, index) => {
    setActiveBarIndex(index);
  };

  const handleBarMouseOut = () => {
    setActiveBarIndex(null);
  };

  // Ticket Component
  const Ticket = ({ asset }) => {
    return (
      <motion.div
        initial={{ rotateY: 90, opacity: 0 }}
        animate={{ rotateY: 0, opacity: 1 }}
        transition={{ duration: 0.6 }}
      >
        <StyledTicketCard>
          <TicketHeader>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {asset} Lottery Ticket
            </Typography>
          </TicketHeader>
          <TicketContent>
            <Avatar
              alt={asset}
              src={asset === 'ERMO' ? '/ermo.png' : '/ada.png'}
              sx={{ width: 80, height: 80, marginBottom: 2, margin: '0 auto' }}
            />
            <Typography variant="body2" sx={{ marginBottom: 1 }}>
              Serial Number:
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
              {generateSerialNumber()}
            </Typography>
            <Divider />
            <Typography variant="body2" sx={{ marginTop: 2 }}>
              Good Luck!
            </Typography>
          </TicketContent>
          <TicketFooter>
            <Typography variant="caption">ermoapp.com</Typography>
          </TicketFooter>
        </StyledTicketCard>
      </motion.div>
    );
  };

  // Ticket Carousel Component
  const TicketCarousel = ({ tickets }) => {
    // Combine ERMO and ADA tickets
    const combinedTickets = tickets.ticketTransactions;

    // Add an initial card that displays the total amount of ADA and ERMO tickets
    const totalTicketsCard = (
      <motion.div
        key="total-tickets"
        initial={{ rotateY: 90, opacity: 0 }}
        animate={{ rotateY: 0, opacity: 1 }}
        transition={{ duration: 0.6 }}
      >
        <StyledTicketCard>
          <TicketHeader>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Total Tickets
            </Typography>
          </TicketHeader>
          <TicketContent>
            <Typography variant="body1">ERMO Tickets: {tickets.ERMO}</Typography>
            <Typography variant="body1">ADA Tickets: {tickets.ADA}</Typography>
            <Typography variant="body1">Total: {tickets.ERMO + tickets.ADA}</Typography>
          </TicketContent>
          <TicketFooter>
            <Typography variant="caption">Thank you for participating!</Typography>
          </TicketFooter>
        </StyledTicketCard>
      </motion.div>
    );

    // Create ticket cards
    const ticketCards = combinedTickets.map((asset, index) => (
      <Ticket key={index} asset={asset} />
    ));

    return (
      <Box
        sx={{
          display: 'flex',
          overflowX: 'auto',
          padding: 2,
          '& > *': {
            marginRight: 2,
            flexShrink: 0,
          },
        }}
      >
        {totalTicketsCard}
        {ticketCards}
      </Box>
    );
  };

  return (
    <Container>
      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <MainGrid container spacing={isMobile ? 2 : 4}>
        {/* Left Column */}
        <Grid item xs={12} md={6}>
          {/* Timer */}
          {endTime && <Timer endTime={endTime} onCountdownEnd={() => setIsCountdownOver(true)} />}

          {/* Tickets Sold */}
          <StyledCard sx={{ marginTop: 2 }}>
            <Typography
              variant="h5"
              sx={{ marginBottom: 2, fontWeight: 'bold', textAlign: 'center' }}
            >
              Tickets Sold
            </Typography>
            {loadingData ? (
              <Box display="flex" justifyContent="center" alignItems="center" height={350}>
                <CircularProgress />
              </Box>
            ) : (
              <Box sx={{ width: '100%', height: 350 }}>
                <ResponsiveContainer>
                  <BarChart
                    data={ticketsSoldData}
                    margin={{ top: 30, left: 20, right: 20, bottom: 20 }} // Increased top margin
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" stroke="#333" />
                    <YAxis allowDecimals={false} stroke="#333" />
                    <RechartsTooltip
                      formatter={(value) => value.toLocaleString()}
                      contentStyle={{ border: '1px solid #ccc', color: '#000' }}
                      itemStyle={{ color: '#000' }}
                      labelStyle={{ color: '#000' }}
                    />
                    <Bar
                      dataKey="tickets"
                      fill="#6D5FFD"
                      isAnimationActive={true}
                      animationDuration={800}
                      animationEasing="ease-out"
                      onMouseOver={handleBarMouseOver}
                      onMouseOut={handleBarMouseOut}
                    >
                      {ticketsSoldData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={index === activeBarIndex ? '#46A0FF' : '#6D5FFD'}
                        />
                      ))}
                      <LabelList
                        dataKey="tickets"
                        position="top"
                        formatter={(value) => value.toLocaleString()}
                        fill="#333" // Label color
                      />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            )}
            {/* Display total tickets sold */}
            {loadingData ? (
              <Skeleton variant="text" width={200} height={30} sx={{ margin: '20px auto' }} />
            ) : (
              <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center', marginTop: 2 }}>
                Total Tickets Sold: {(ticketsSold.ERMO + ticketsSold.ADA).toLocaleString()}
              </Typography>
            )}
            {/* "My Tickets" Button */}
            <Box display="flex" justifyContent="center" marginTop={2}>
              <SmallButton onClick={handleMyTickets} disabled={loadingMyTickets}>
                {loadingMyTickets ? 'Loading...' : 'My Tickets'}
              </SmallButton>
            </Box>
          </StyledCard>

          {/* Prize Distribution */}
          <Box sx={{ marginTop: 2 }}>
            <PrizeDistribution prizes={prizes} loading={loadingData} />
          </Box>
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={6}>
          {/* Lottery Card */}
          <StyledCard>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              sx={{
                position: 'relative',
                background: 'linear-gradient(135deg, #6D5FFD 0%, #46A0FF 100%)',
                borderRadius: theme.spacing(2),
                padding: theme.spacing(3),
                color: '#fff',
                textAlign: 'center',
                marginBottom: theme.spacing(3),
              }}
            >
              <Avatar
                alt="Lottery"
                src={selectedAsset === 'ERMO' ? '/ermo.png' : '/ada.png'} // Dynamic image based on selected asset
                sx={{
                  width: 80,
                  height: 80,
                  marginBottom: 2,
                  border: '4px solid #fff',
                }}
              />
              <HeaderTypography variant="h5" sx={{ color: '#fff', marginBottom: 0 }}>
                Lottery - {selectedAsset}
              </HeaderTypography>
            </Box>

            <Typography
              variant="body1"
              sx={{
                fontSize: '1rem',
                marginTop: 2,
                textAlign: 'center',
                color: theme.palette.text.secondary,
              }}
            >
              Participate in the lottery by betting {selectedAsset}. Each asset has its own ticket
              rate.
            </Typography>

            <Divider sx={{ marginY: 3 }} />

            {/* Asset Selection */}
            <Box marginTop={2}>
              <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                Select Asset:
              </Typography>
              <StyledFormControl variant="outlined">
                <Select
                  value={selectedAsset}
                  onChange={handleAssetChange}
                  displayEmpty
                  renderValue={(value) => {
                    if (value) {
                      return value;
                    }
                    return 'Select Asset';
                  }}
                >
                  <MenuItem value="ERMO">ERMO</MenuItem>
                  <MenuItem value="ADA">ADA</MenuItem>
                  {/* Add other assets here when available */}
                </Select>
              </StyledFormControl>

              <Typography variant="subtitle1" sx={{ marginTop: 3 }}>
                Enter Amount (multiples of {assetTicketMapping[selectedAsset].multiple}{' '}
                {selectedAsset}):
              </Typography>

              <StyledTextField
                variant="outlined"
                type="number"
                value={customAmount}
                onChange={handleCustomAmountChange}
                placeholder={`Enter amount (multiple of ${assetTicketMapping[selectedAsset].multiple} ${selectedAsset})`}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{selectedAsset}</InputAdornment>,
                }}
              />

              {/* Display number of tickets */}
              {tickets > 0 && (
                <Box display="flex" alignItems="center" marginTop={2}>
                  <TicketIcon color="primary" sx={{ marginRight: 1 }} />
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    You will get {tickets} ticket{tickets > 1 ? 's' : ''}.
                  </Typography>
                </Box>
              )}
            </Box>

            {/* Create Transaction Button */}
            <Box marginTop={4} display="flex" flexDirection="column" alignItems="center">
              <StyledButton
                onClick={handleCreateTransaction}
                disabled={loading || isCountdownOver || !isConnected}
                startIcon={
                  loading ? <CircularProgress size={20} color="inherit" /> : <DownloadIcon />
                }
              >
                {loading ? 'Creating Transaction...' : 'Create Transaction'}
              </StyledButton>
              {!isConnected && (
                <Box
                  display="flex"
                  alignItems="center"
                  color="error.main"
                  sx={{ marginTop: 2, cursor: 'pointer' }}
                  onClick={connectWallet}
                >
                  <WalletIcon sx={{ marginRight: 1 }} />
                  <Typography variant="body2">
                    Please connect your wallet to participate.
                  </Typography>
                </Box>
              )}
            </Box>

            <Divider sx={{ marginY: 3 }} />

            {/* Lottery Mechanics Description */}
            <Box textAlign="left" marginTop={2}>
              <Box display="flex" alignItems="center" marginBottom={1}>
                <InfoIcon color="primary" sx={{ marginRight: 1 }} />
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  How It Works
                </Typography>
              </Box>
              {[
                `Every ${assetTicketMapping[selectedAsset].multiple} ${selectedAsset} equals 1 ticket.`,
                'There are 3 winners.',
                '5% is burned, 5% goes to the jackpot.',
                'First winner receives 50% of the prize pool.',
                'Second winner receives 30% of the prize pool.',
                'Third winner receives 20% of the prize pool.',
                'There is a 1/100 chance to win the jackpot.',
                'If the jackpot is not won, it accumulates for the next week.',
              ].map((item, index) => (
                <Box key={index} display="flex" alignItems="flex-start" sx={{ marginTop: 1 }}>
                  <Box
                    sx={{
                      width: 8,
                      height: 8,
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: '50%',
                      marginRight: 1,
                      marginTop: 0.7,
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{ wordBreak: 'break-word', color: theme.palette.text.secondary }}
                  >
                    {item}
                  </Typography>
                </Box>
              ))}
            </Box>

            {/* Popup Dialog for Transaction Success */}
            <Dialog open={openPopup} onClose={handleClosePopup} fullWidth maxWidth="sm">
              <DialogTitle>Transaction Successful!</DialogTitle>
              <DialogContent>
                <Box id="popup-details">
                  <Typography variant="body1" sx={{ marginBottom: 1, wordBreak: 'break-word' }}>
                    <strong>Transaction Hash:</strong> {popupDetails.txHash || 'N/A'}
                  </Typography>
                  <Typography variant="body1" sx={{ marginBottom: 1 }}>
                    <strong>Asset:</strong> {popupDetails.asset || 'N/A'}
                  </Typography>
                  <Typography variant="body1" sx={{ marginBottom: 1 }}>
                    <strong>Amount:</strong>{' '}
                    {popupDetails.amount !== undefined
                      ? popupDetails.amount.toLocaleString()
                      : '0'}{' '}
                    {popupDetails.asset}
                  </Typography>
                  <Typography variant="body1" sx={{ marginBottom: 1 }}>
                    <strong>Tickets:</strong>{' '}
                    {popupDetails.tickets !== undefined
                      ? popupDetails.tickets.toLocaleString()
                      : '0'}{' '}
                    Ticket{popupDetails.tickets > 1 ? 's' : ''}
                  </Typography>
                  <Box display="flex" justifyContent="center" marginTop={2}>
                    <Avatar
                      alt="Lottery"
                      src={popupDetails.asset === 'ERMO' ? '/ermo.png' : '/ada.png'} // Use asset-specific images
                      sx={{
                        width: 80,
                        height: 80,
                      }}
                    />
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <StyledButton
                      onClick={handleCopyPayout}
                      startIcon={<ContentCopyIcon />}
                      fullWidth
                    >
                      Copy Hash
                    </StyledButton>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledButton onClick={handleClosePopup} startIcon={<CloseIcon />} fullWidth>
                      Close
                    </StyledButton>
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>
          </StyledCard>
        </Grid>
      </MainGrid>

      {/* Ticket Popup Dialog */}
      <Dialog open={openTicketDialog} onClose={handleCloseTicketDialog} fullWidth maxWidth="md">
        <DialogTitle>Your Lottery Tickets</DialogTitle>
        <DialogContent>
          {myTickets.ticketTransactions && myTickets.ticketTransactions.length > 0 ? (
            <TicketCarousel tickets={myTickets} />
          ) : (
            <Typography variant="body1" sx={{ marginTop: 2, textAlign: 'center' }}>
              You have no tickets.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleCloseTicketDialog} startIcon={<CloseIcon />} fullWidth>
            Close
          </StyledButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Lottery;
