// src/pages/Home.js

import React from 'react';
import { Grid, CardActionArea, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled, keyframes } from '@mui/system';
import { BetCard, CardContainer, HeaderTypography } from './StyledComponents'; // Ensure the path is correct

// ---------------------- Keyframe Animations ----------------------

const rotate = keyframes`
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
`;

const scaleUp = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
`;

// ---------------------- Styled Components ----------------------

// Center the container with padding and max-width
const CenteredCardContainer = styled(CardContainer)(({ theme }) => ({
  padding: theme.spacing(5, 2),
  maxWidth: '1200px',
  margin: 'auto',
}));

// Styled Box for Icon with animations
const IconBox = styled(Box)(({ theme }) => ({
  width: '70px',
  height: '70px',
  marginBottom: theme.spacing(2),
  '& img': {
    width: '100%',
    height: '100%',
    transition: 'transform 0.3s ease',
  },
  [`${CardActionArea}:hover & img`]: {
    animation: `${rotate} 4s linear infinite, ${scaleUp} 0.3s forwards`,
  },
}));

// ---------------------- Home Component ----------------------

const Home = () => {
  const features = [
    {
      name: 'Bet',
      icon: '/DICE.svg', // Ensure this icon exists in the public folder
      description: 'Place your bets and win big!',
      path: '/bet',
    },
    {
      name: 'Lottery',
      icon: '/CUP.svg', // Ensure this icon exists in the public folder
      description: 'Participate in exciting lotteries.',
      path: '/lottery',
    },
  ];

  return (
    <CenteredCardContainer>
      <Grid container spacing={4} justifyContent="center">
        {features.map((feature) => (
          <Grid item xs={12} sm={6} md={6} key={feature.name}>
            <Box display="flex" justifyContent="center">
              <CardActionArea
                component={Link}
                to={feature.path}
                aria-label={`Navigate to ${feature.name}`}
                sx={{
                  borderRadius: '12px',
                  boxShadow: 3,
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  padding: { xs: 2, sm: 3 },
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: 6,
                  },
                  width: '100%',
                  maxWidth: '300px',
                }}
              >
                <BetCard
                  sx={{
                    textAlign: 'center',
                    padding: { xs: 2, sm: 3 },
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <IconBox
                      sx={{
                        width: { xs: '50px', sm: '60px', md: '70px' },
                        height: { xs: '50px', sm: '60px', md: '70px' },
                      }}
                    >
                      <img src={feature.icon} alt={`${feature.name} Icon`} />
                    </IconBox>
                    <HeaderTypography
                      variant="h6"
                      sx={{
                        fontSize: { xs: '1rem', md: '1.2rem' },
                        fontWeight: 700,
                      }}
                    >
                      {feature.name}
                    </HeaderTypography>
                  </Box>

                  <Box
                    height="2px"
                    bgcolor="#000"
                    margin="8px 0"
                    width="80%"
                    mx="auto"
                  ></Box>

                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "'Schoolbell', cursive",
                      color: '#555',
                      fontSize: { xs: '0.85rem', sm: '1rem' },
                      padding: { xs: '0 12px', sm: '0 16px' },
                    }}
                  >
                    {feature.description}
                  </Typography>
                </BetCard>
              </CardActionArea>
            </Box>
          </Grid>
        ))}
      </Grid>
    </CenteredCardContainer>
  );
};

export default React.memo(Home);
