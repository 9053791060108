/* StyledComponents.js */

import { styled, keyframes } from '@mui/system';
import {
  Box,
  Card,
  Typography,
  Button,
  FormControl,
  TextField,
  Dialog,
  IconButton,
  Avatar, // Added Avatar import
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// Keyframes for animations
const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 99, 132, 0.7);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 10px 5px rgba(255, 99, 132, 0.5);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 99, 132, 0);
  }
`;

// BetCard styled component (Reduced size)
export const BetCard = styled(Card)(({ theme }) => ({
  border: '3px solid #000',
  padding: '15px', // Reduced padding
  margin: '10px', // Reduced margin
  textAlign: 'center',
  fontFamily: "'Schoolbell', cursive",
  boxShadow: '6px 6px 0px #000', // Reduced shadow
  backgroundColor: '#FFFFFF',
  borderRadius: '15px', // Reduced border radius
  width: '100%',
  maxWidth: '300px', // Reduced maxWidth
  minHeight: '300px', // Reduced minHeight
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '@media (min-width: 960px)': {
    width: '250px', // Further reduction for larger screens
    margin: '10px',
  },
  '@media (max-width: 600px)': {
    padding: '10px',
    width: '90%',
    margin: '10px auto',
  },
}));

// CardContainer styled component
export const CardContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '20px', // Added padding to prevent collision with Navbar
  '@media (min-width: 960px)': {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
});

// HeaderTypography styled component
export const HeaderTypography = styled(Typography)({
  fontFamily: "'Schoolbell', cursive",
  color: '#333',
  fontSize: '1.4rem', // Reduced font size
  fontWeight: 'bold',
  marginBottom: '8px', // Reduced margin
  textShadow: '1px 1px 2px #FFF', // Reduced text shadow
  wordBreak: 'break-word',
});

// StyledButton styled component
export const StyledButton = styled(Button)(({ disabled }) => ({
  backgroundColor: disabled ? '#B0B0B0' : '#FF6347',
  color: '#FFFFFF',
  border: '3px solid #000',
  fontFamily: "'Schoolbell', cursive",
  padding: '6px 12px', // Reduced padding
  minWidth: '80px', // Reduced minWidth
  '&:hover': {
    backgroundColor: disabled ? '#B0B0B0' : '#FFA500',
    color: disabled ? '#FFFFFF' : '#000',
  },
  boxShadow: '3px 3px 0px #000', // Reduced shadow
  borderRadius: '8px', // Reduced border radius
  fontSize: '0.9rem', // Reduced font size
  textTransform: 'none',
}));

// ButtonContainer styled component
export const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '15px', // Reduced margin
  '@media (max-width: 600px)': {
    flexDirection: 'row',
    gap: '8px',
    flexWrap: 'wrap',
  },
});

// StyledFormControl styled component
export const StyledFormControl = styled(FormControl)({
  width: '100%',
  fontFamily: "'Schoolbell', cursive",
  marginTop: '10px', // Reduced margin
  '& .MuiSelect-select': {
    padding: '10px', // Reduced padding
    backgroundColor: '#FFF',
    borderRadius: '8px', // Reduced border radius
    border: '2px solid #000',
    boxShadow: '3px 3px 0px #000', // Reduced shadow
    wordBreak: 'break-word',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
});

// StyledTextField styled component
export const StyledTextField = styled(TextField)({
  width: '100%',
  fontFamily: "'Schoolbell', cursive",
  marginTop: '10px', // Reduced margin
  '& .MuiInputBase-input': {
    fontFamily: "'Schoolbell', cursive",
    padding: '10px', // Reduced padding
    backgroundColor: '#FFF',
    borderRadius: '8px', // Reduced border radius
    border: '2px solid #000',
    boxShadow: '3px 3px 0px #000', // Reduced shadow
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '& .MuiInputAdornment-root': {
    fontFamily: "'Schoolbell', cursive",
  },
});

// InfoDialogStyled styled component
export const InfoDialogStyled = styled(Dialog)({
  '& .MuiPaper-root': {
    border: '3px solid #000',
    boxShadow: '6px 6px 0px #000',
    padding: '25px', // Reduced padding
    fontFamily: "'Schoolbell', cursive",
    borderRadius: '15px', // Reduced border radius
    maxHeight: '80vh', // Reduced maxHeight
    overflowY: 'auto',
    width: '90%',
    maxWidth: '600px', // Reduced maxWidth
  },
  '& .MuiDialogTitle-root': {
    fontSize: '1.8rem', // Reduced font size
    color: '#000',
    textShadow: 'none',
    textAlign: 'center',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px', // Reduced gap
    wordBreak: 'break-word',
    '@media (max-width: 600px)': {
      fontSize: '1.3rem', // Further reduced for small screens
    },
  },
  '& .MuiDialogContent-root': {
    color: '#000',
    fontFamily: "'Schoolbell', cursive",
    textAlign: 'left',
  },
  '& .MuiDialogActions-root': {
    justifyContent: 'space-between',
  },
});

// PopupDialogStyled styled component
export const PopupDialogStyled = styled(Dialog)({
  '& .MuiPaper-root': {
    border: '3px solid #000',
    boxShadow: '6px 6px 0px #000',
    padding: '25px', // Reduced padding
    fontFamily: "'Schoolbell', cursive",
    borderRadius: '15px', // Reduced border radius
    position: 'relative',
    width: '100%',
    maxWidth: '400px', // Reduced maxWidth
    '@media (max-width: 600px)': {
      padding: '15px', // Further reduced for small screens
    },
  },
  '& .MuiDialogTitle-root': {
    fontSize: '1.8rem', // Reduced font size
    color: '#000',
    textShadow: 'none',
    textAlign: 'center',
    fontWeight: 'bold',
    wordBreak: 'break-word',
  },
  '& .MuiDialogContent-root': {
    color: '#000',
    fontFamily: "'Schoolbell', cursive",
    textAlign: 'left',
    position: 'relative',
  },
  '& .MuiDialogActions-root': {
    justifyContent: 'space-between',
  },
  '& img': {
    position: 'absolute',
    top: '10px',
    right: '10px',
    width: '60px', // Reduced size
    height: '60px', // Reduced size
    border: '3px solid #000',
    borderRadius: '50%',
    boxShadow: '3px 3px 0px #000', // Reduced shadow
  },
});

// AvatarWithBorder styled component
export const AvatarWithBorder = styled(Avatar)({
  border: '2px solid #000',
  width: 50, // Reduced size
  height: 50, // Reduced size
  marginRight: '8px', // Reduced margin
});

// AssetIcon styled component
export const AssetIcon = styled('img')({
  width: 25, // Reduced size
  height: 25, // Reduced size
  borderRadius: '50%',
  border: '2px solid #000',
  objectFit: 'cover',
  wordBreak: 'break-word',
});

// BoxedAsset styled component
export const BoxedAsset = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '6px', // Reduced padding
  border: '2px solid #000',
  borderRadius: '8px', // Reduced border radius
  boxShadow: '3px 3px 0px #000', // Reduced shadow
  margin: '4px 0', // Reduced margin
  backgroundColor: '#f9f9f9',
  wordBreak: 'break-word',
});
