// src/Roulette.js
import React, { useState, useRef, useEffect } from 'react';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import { Howl } from 'howler';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { FixedSizeList as List } from 'react-window';

// Define sound effects using howler.js
const spinSound = new Howl({
  src: ['https://actions.google.com/sounds/v1/cartoon/wood_plank_flicks.ogg'],
  volume: 0.5,
});

const winSound = new Howl({
  src: ['https://actions.google.com/sounds/v1/cartoon/clang_and_wobble.ogg'],
  volume: 0.5,
});

// Inline CSS styles for centralized management
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px 10px',
    fontFamily: `'Roboto', sans-serif`,
    minHeight: '100vh',
    boxSizing: 'border-box',
    color: '#00796b',
    backgroundColor: 'transparent', // Removed background color
  },
  header: {
    fontSize: '2.5rem',
    color: '#00796b',
    marginBottom: '20px',
    textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
    textAlign: 'center',
  },
  controlsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '700px',
    marginBottom: '30px',
    padding: '20px',
    backgroundColor: 'transparent', // Removed background color
    borderRadius: '10px',
    boxShadow: 'none', // Removed box shadow
  },
  bulkAddContainer: { // Renamed for clarity
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: '15px',
    flexWrap: 'wrap',
  },
  select: {
    padding: '12px 20px',
    fontSize: '16px',
    width: '100%',
    maxWidth: '200px',
    border: '2px solid #00796b',
    borderRadius: '5px',
    outline: 'none',
    transition: 'border-color 0.3s',
    backgroundColor: '#ffffff',
    color: '#00796b',
    cursor: 'pointer',
    marginTop: '10px',
  },
  button: {
    padding: '12px 25px',
    fontSize: '16px',
    margin: '5px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#00796b',
    color: '#ffffff',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    transition: 'background-color 0.3s, transform 0.3s',
    minWidth: '100px',
  },
  buttonHover: {
    backgroundColor: '#004d40',
    transform: 'scale(1.05)',
  },
  spinButton: {
    padding: '15px 35px',
    fontSize: '18px',
    marginTop: '20px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '50px',
    background: 'linear-gradient(45deg, #26a69a, #00897b)',
    color: '#fff',
    boxShadow: '0 8px 15px rgba(0, 0, 0, 0.2)',
    transition: 'background 0.3s, transform 0.3s',
    minWidth: '150px',
  },
  spinButtonDisabled: {
    backgroundColor: '#b2dfdb',
    cursor: 'not-allowed',
    opacity: 0.7,
  },
  spinButtonHover: {
    background: 'linear-gradient(45deg, #009688, #00695c)',
    transform: 'scale(1.05)',
  },
  slotContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '40px auto',
    position: 'relative',
    width: '90%',
    maxWidth: '500px',
    height: '120px',
    borderRadius: '15px',
    overflow: 'visible', // Allow zoom animation without clipping
    backgroundColor: 'transparent', // Removed background color
    boxShadow: 'none', // Removed box shadow
    transition: 'all 0.3s ease-in-out',
  },
  slot: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#00796b',
    transition: 'opacity 0.5s ease-in-out, background-color 0.3s ease-in-out',
    padding: '0 10px',
    boxSizing: 'border-box',
    textAlign: 'center',
    wordBreak: 'break-all', // Handle long wallet addresses
    borderRadius: '15px',
    backgroundColor: '#ffffff', // Maintain background during spin for visibility
  },
  winnerText: {
    marginTop: '30px',
    fontSize: '2rem',
    color: '#00796b',
    fontWeight: 'bold',
    textShadow: '1px 1px 3px rgba(0,0,0,0.1)',
    textAlign: 'center',
  },
  participantListContainer: {
    marginTop: '40px',
    width: '90%',
    maxWidth: '700px',
    textAlign: 'left',
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
  },
  participantListHeader: {
    fontSize: '1.5rem',
    color: '#00796b',
    marginBottom: '10px',
  },
  participantList: {
    border: '1px solid #ccc',
    borderRadius: '5px',
    overflow: 'hidden',
  },
  participantRow: {
    padding: '10px',
    borderBottom: '1px solid #eee',
    fontSize: '1rem',
    backgroundColor: '#fafafa',
  },
  downloadButtonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px', // Increased margin-top to separate from other content
    flexWrap: 'wrap',
  },
  downloadButton: {
    padding: '10px 20px',
    fontSize: '16px',
    margin: '5px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#26a69a',
    color: '#ffffff',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    transition: 'background-color 0.3s, transform 0.3s',
    minWidth: '120px',
  },
  downloadButtonHover: {
    backgroundColor: '#00796b',
    transform: 'scale(1.05)',
  },
  dropdownContainer: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: '400px',
    justifyContent: 'center',
  },
  dropdownLabel: {
    marginRight: '10px',
    fontSize: '16px',
    color: '#00796b',
  },
  winnerListContainer: {
    marginTop: '30px',
    width: '100%',
    maxWidth: '700px',
    textAlign: 'left',
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
  },
  winnerListHeader: {
    fontSize: '1.5rem',
    color: '#00796b',
    marginBottom: '10px',
  },
  winnerList: {
    backgroundColor: '#ffffff',
    padding: '10px',
    borderRadius: '5px',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
  },
  winnerItem: {
    padding: '10px 0',
    borderBottom: '1px solid #eee',
    fontSize: '1rem',
    wordBreak: 'break-all',
  },
};

// Keyframes for Zoom In and Out Animation
const zoomInOutKeyframes = `
  @keyframes zoomInOut {
    0% {
      transform: scale(1);
    }
    40% {
      transform: scale(1.5);
    }
    70% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }
  .zoom-animation {
    animation: zoomInOut 3s ease-in-out;
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    .controlsContainer {
      padding: 10px;
    }
    .slotContainer {
      height: 100px;
    }
    .header {
      font-size: 2rem;
    }
    .winnerText {
      font-size: 1.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .controlsContainer {
      padding: 5px;
    }
    .slotContainer {
      height: 80px;
    }
    .header {
      font-size: 1.8rem;
    }
    .winnerText {
      font-size: 1.2rem;
    }
    .bulkAddContainer, .dropdownContainer {
      flex-direction: column;
      align-items: stretch;
    }
    .select {
      max-width: 100%;
      margin-top: 10px;
    }
    .spinButton, .downloadButton {
      min-width: 100px;
      padding: 10px 15px;
    }
  }
`;

function Roulette() {
  const [winners, setWinners] = useState([]); // Stores the list of winners
  const [isSpinning, setIsSpinning] = useState(false); // Indicates if spinning is active
  const [winnerType, setWinnerType] = useState('Ada'); // Selected winner type (Ada or Ermo)
  const [participants, setParticipants] = useState([
    // Prepopulate with some dummy wallet addresses
    'addr1q9djcwfxyfckkmch2ct52dmgu66y0xh3s8vk8tlvtl03deceuq2lhywhkljq5ldn5vdrzs8e59yawpzkleuk387m6sgs82cj7u',
    'addr1qph82gi3prkp46zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz',
    'addr1qo6wssl4j1js39abcdefghijklmnoqrstuvwxyz1234567890abcdef1234567890abcdef1234567890abcdef12345678',
    'addr1qxyz1234567890abcdef1234567890abcdef1234567890abcdef1234567890abcdef1234567890abcdef12345678',
    'addr1qabcdef1234567890abcdef1234567890abcdef1234567890abcdef1234567890abcdef1234567890abcdef12345678',
  ]); // Initial dummy participants
  const [currentIndex, setCurrentIndex] = useState(0); // Current participant index displayed
  const [hoveredButton, setHoveredButton] = useState(null); // Tracks hovered button for styling
  const { width, height } = useWindowSize(); // Window dimensions for confetti sizing

  const [confetti, setConfetti] = useState(false); // Controls confetti display
  const [winner, setWinner] = useState(null); // Stores the current winner
  const [isZoomAnimating, setIsZoomAnimating] = useState(false); // Controls zoom animation

  // References to manage spinning and winner selection
  const timerRef = useRef(null); // To store the spinning interval
  const finalizeTimeoutRef = useRef(null); // To store the timeout for finalizing spin

  // Function to generate multiple participants (e.g., 100 or 10,000)
  const generateParticipants = (count) => {
    const newParticipants = Array.from(
      { length: count },
      () => `addr1q${Math.random().toString(36).substring(2, 68)}`
    );
    setParticipants((prev) => [...prev, ...newParticipants]);
    alert(
      `${count} participants added! Total participants: ${participants.length + count}`
    );
  };

  // Function to initiate the spinning animation and pick a winner
  const spinRoulette = () => {
    if (participants.length < 1) {
      alert('Add at least one participant to start the roulette!');
      return;
    }

    setIsSpinning(true);
    setConfetti(false);
    setWinner(null); // Reset any previous winner
    spinSound.play(); // Play spin sound

    // Start spinning: update currentIndex every 100ms
    timerRef.current = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * participants.length);
      setCurrentIndex(randomIndex);
    }, 100); // Update every 100ms

    // Stop spinning after 5 seconds
    setTimeout(() => {
      clearInterval(timerRef.current);

      // Pick a random winner at the end of spinning
      const winnerIndex = Math.floor(Math.random() * participants.length);
      const selectedWinner = participants[winnerIndex];
      setCurrentIndex(winnerIndex); // Ensure the slot stops at the winner

      // Set the winner after a 2-second delay
      finalizeTimeoutRef.current = setTimeout(() => {
        setWinner(selectedWinner); // Set the winner to be displayed
        setWinners((prevWinners) => [
          ...prevWinners,
          { name: selectedWinner, type: winnerType },
        ]);
        setIsSpinning(false);
        winSound.play(); // Play win sound
        setConfetti(true);
        setIsZoomAnimating(true); // Start zoom animation

        // Remove the winner from participants to prevent re-selection
        setParticipants((prevParticipants) =>
          prevParticipants.filter((_, index) => index !== winnerIndex)
        );

        // Clean up references
        finalizeTimeoutRef.current = null;

        // Stop zoom animation after 3 seconds
        setTimeout(() => {
          setIsZoomAnimating(false);
        }, 3000);
      }, 2000); // 2 seconds delay
    }, 5000); // 5 seconds spinning duration
  };

  // Function to get the current participant to display
  const getCurrentParticipant = () => {
    if (winner) {
      return winner;
    }
    return participants[currentIndex] || 'No Participants';
  };

  // Function to download participant list as CSV
  const downloadCSV = () => {
    if (participants.length === 0) {
      alert('No participants to download.');
      return;
    }
    const ws = XLSX.utils.json_to_sheet(
      participants.map((p, i) => ({ ID: i + 1, WalletAddress: p }))
    );
    const csvData = XLSX.utils.sheet_to_csv(ws);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'participants.csv');
  };

  // Function to download participant list as XLSX
  const downloadXLSX = () => {
    if (participants.length === 0) {
      alert('No participants to download.');
      return;
    }
    const ws = XLSX.utils.json_to_sheet(
      participants.map((p, i) => ({ ID: i + 1, WalletAddress: p }))
    );
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Participants');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    saveAs(blob, 'participants.xlsx');
  };

  // Inject zoom animation and responsive keyframes into the document head
  useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = `
      ${zoomInOutKeyframes}

      /* Responsive Design */
      @media (max-width: 768px) {
        .controlsContainer {
          padding: 10px;
        }
        .slotContainer {
          height: 100px;
        }
        .header {
          font-size: 2rem;
        }
        .winnerText {
          font-size: 1.5rem;
        }
      }
      
      @media (max-width: 480px) {
        .controlsContainer {
          padding: 5px;
        }
        .slotContainer {
          height: 80px;
        }
        .header {
          font-size: 1.8rem;
        }
        .winnerText {
          font-size: 1.2rem;
        }
        .bulkAddContainer, .dropdownContainer {
          flex-direction: column;
          align-items: stretch;
        }
        .select {
          max-width: 100%;
          margin-top: 10px;
        }
        .spinButton, .downloadButton {
          min-width: 100px;
          padding: 10px 15px;
        }
      }
    `;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  // Clean up intervals and timeouts on component unmount
  useEffect(() => {
    return () => {
      clearInterval(timerRef.current);
      clearTimeout(finalizeTimeoutRef.current);
    };
  }, []);

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>🎟️ Roulette</h1>
      <div style={styles.controlsContainer} className="controlsContainer">
        {/* Bulk Participant Addition Controls */}
        <div style={styles.bulkAddContainer} className="bulkAddContainer">
          <button
            onClick={() => generateParticipants(100)}
            style={{
              ...styles.button,
              ...(hoveredButton === 'add100' ? styles.buttonHover : {}),
              cursor: isSpinning ? 'not-allowed' : 'pointer',
              backgroundColor: isSpinning ? '#b2dfdb' : '#00796b',
            }}
            onMouseEnter={() => !isSpinning && setHoveredButton('add100')}
            onMouseLeave={() => setHoveredButton(null)}
            aria-label="Add 100 Participants"
            disabled={isSpinning}
          >
            Add 100
          </button>
          <button
            onClick={() => generateParticipants(10000)}
            style={{
              ...styles.button,
              ...(hoveredButton === 'add10000' ? styles.buttonHover : {}),
              cursor: isSpinning ? 'not-allowed' : 'pointer',
              backgroundColor: isSpinning ? '#b2dfdb' : '#00796b',
            }}
            onMouseEnter={() => !isSpinning && setHoveredButton('add10000')}
            onMouseLeave={() => setHoveredButton(null)}
            aria-label="Add 10,000 Participants"
            disabled={isSpinning}
          >
            Add 10,000
          </button>
        </div>
        {/* Winner Type Selection */}
        <div style={styles.dropdownContainer} className="dropdownContainer">
          <label htmlFor="winnerType" style={styles.dropdownLabel}>
            Assign Winner To:
          </label>
          <select
            id="winnerType"
            value={winnerType}
            onChange={(e) => setWinnerType(e.target.value)}
            style={styles.select}
            aria-label="Select Winner Type"
            disabled={isSpinning}
          >
            <option value="Ada">Ada</option>
            <option value="Ermo">Ermo</option>
          </select>
        </div>
        {/* Spin Control */}
        <button
          onClick={spinRoulette}
          style={{
            ...styles.spinButton,
            ...(isSpinning ? styles.spinButtonDisabled : {}),
            ...(hoveredButton === 'spin' && !isSpinning
              ? styles.spinButtonHover
              : {}),
          }}
          disabled={isSpinning || participants.length === 0}
          onMouseEnter={() => !isSpinning && setHoveredButton('spin')}
          onMouseLeave={() => setHoveredButton(null)}
          aria-label="Pick Winner"
        >
          {isSpinning ? 'Spinning...' : 'Pick Winner'}
        </button>
      </div>
      {/* Spinning Reel */}
      <div style={styles.slotContainer} className="slotContainer">
        <div
          className={isZoomAnimating ? 'zoom-animation' : ''}
          style={{
            ...styles.slot,
            backgroundColor: isSpinning ? '#e0f2f1' : '#ffffff', // Maintain background during spin for visibility
            opacity: isSpinning ? 0.8 : 1, // Slight fade during spinning
            maxWidth: '100%', // Ensure the content doesn't overflow during zoom
            transition: 'transform 0.3s ease-in-out', // Smooth transition for scaling
          }}
        >
          🎟️ {/* Lottery Ticket Emoji */}
          <span style={{ marginLeft: '10px' }}>{getCurrentParticipant()}</span>
        </div>
      </div>
      {/* Winners Display */}
      {winners.length > 0 && (
        <div style={styles.winnerText}>
          <h2>🏆 Winners</h2>
          <div style={styles.winnerList}>
            {winners
              .slice()
              .reverse()
              .map((winnerItem, index) => (
                <div key={index} style={styles.winnerItem}>
                  <strong>
                    Winner {winners.length - index} ({winnerItem.type}):
                  </strong>{' '}
                  {winnerItem.name}
                </div>
              ))}
          </div>
        </div>
      )}
      {/* Participant List Visualization */}
      <div style={styles.participantListContainer}>
        <div style={styles.participantListHeader}>
          📋 Total Participants: {participants.length}
        </div>
        {participants.length > 0 && (
          <div style={styles.participantList} aria-label="Participant List">
            <List
              height={300}
              itemCount={participants.length}
              itemSize={40}
              width={'100%'}
            >
              {({ index, style }) => (
                <div style={{ ...styles.participantRow, ...style }}>
                  {participants[index]}
                </div>
              )}
            </List>
          </div>
        )}
      </div>
      {/* Download Buttons at the Bottom */}
      <div style={styles.downloadButtonsContainer}>
        <button
          onClick={downloadCSV}
          style={{
            ...styles.downloadButton,
            ...(hoveredButton === 'downloadCSV'
              ? styles.downloadButtonHover
              : {}),
          }}
          onMouseEnter={() => setHoveredButton('downloadCSV')}
          onMouseLeave={() => setHoveredButton(null)}
          aria-label="Download CSV"
          disabled={isSpinning || participants.length === 0}
        >
          Download CSV
        </button>
        <button
          onClick={downloadXLSX}
          style={{
            ...styles.downloadButton,
            ...(hoveredButton === 'downloadXLSX'
              ? styles.downloadButtonHover
              : {}),
          }}
          onMouseEnter={() => setHoveredButton('downloadXLSX')}
          onMouseLeave={() => setHoveredButton(null)}
          aria-label="Download XLSX"
          disabled={isSpinning || participants.length === 0}
        >
          Download XLSX
        </button>
      </div>
      {/* Confetti Celebration */}
      {confetti && <Confetti width={width} height={height} />}
    </div>
  );
}

export default Roulette;
